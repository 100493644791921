import styled from 'styled-components';
import appleStoreIcon from '../../images/appstore.png';
import googleStoreIcon from '../../images/playstore.png';

export const Container = styled.div`
  display: flex;
`;

export const StoreLink = styled.a``;

export const AppleLogo = styled.img.attrs({ src: appleStoreIcon })`
  height: 42px;
  display: inline-block;
  margin-right: 21px;
`;

export const GoogleLogo = styled.img.attrs({ src: googleStoreIcon })`
  height: 42px;
  display: inline-block;
`;
