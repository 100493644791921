import firebase from 'firebase';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Container, AppleLogo, GoogleLogo, StoreLink } from './storeIcons.css';

const query = graphql`
  query StoreIconsQuery {
    homeJson {
      storeCta {
        appstoreLink
        playstoreLink
        appstoreAriaLabel
        playtoreAriaLabel
        appstoreAlt
        playstoreAlt
      }
    }
  }
`;

const StoreIcons = ({ className }) => {
  const data = useStaticQuery(query).homeJson.storeCta;

  return (
    <Container className={className}>
      <StoreLink
        onClick={() => {
          firebase.analytics().logEvent('apple_store_link_clicked');
        }}
        href={data.appstoreLink}
        target="__blank"
        rel="noopener noreferrer"
        aria-label={data.appstoreAriaLabel}
      >
        <AppleLogo alt={data.appstoreAlt} />
      </StoreLink>
      <StoreLink
        onClick={() => {
          firebase.analytics().logEvent('google_store_link_clicked');
        }}
        href={data.playstoreLink}
        target="__blank"
        rel="noopener noreferrer"
        aria-label={data.playstoreAriaLabel}
      >
        <GoogleLogo alt={data.playstoreAlt} />
      </StoreLink>
    </Container>
  );
};

StoreIcons.propTypes = {
  className: PropTypes.string,
};

export default StoreIcons;
